import styles from "./styles.module.scss";

export const CSS_TRANSITION_CLASS_NAMES = {
  appear: styles["root--appear"],
  appearActive: styles["root--appearActive"],
  appearDone: styles["root--appearDone"],
  enter: styles["root--enter"],
  enterActive: styles["root--enterActive"],
  enterDone: styles["root--enterDone"],
  exit: styles["root--exit"],
  exitActive: styles["root--exitActive"],
  exitDone: styles["root--exitDone"],
};

export const DEFAULT_CSS_TRANSITION_TIMEOUT = 150;

export const PORTAL_CONTAINER_SELECTOR = "#modals";
