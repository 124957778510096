import { useMemo } from "react";

import cn from "classnames";

import { TypographyProps } from "./types";

import styles from "./styles.module.scss";

export const useTypographyClassNames = ({
  variant,
  align,
  display,
  className,
  color,
}: TypographyProps) =>
  useMemo(
    () =>
      cn(
        {
          [styles.center]: align === "center",
          [styles.left]: align === "left",
          [styles.right]: align === "right",
          [styles.inline]: display === "inline",
          [styles.inlineBlock]: display === "inline-block",
          [styles.block]: display === "block",
          [styles.flex]: display === "flex",
          [styles.inlineFlex]: display === "inline-flex",
          [styles.grid]: display === "grid",
          [styles.inlineGrid]: display === "inline-grid",
          [styles.body]: variant === "body",
          [styles.bodySmall]: variant === "body-small",
          [styles.bodySubtitle]: variant === "body-subtitle",
          [styles.bodyTiny]: variant === "body-tiny",
          [styles.bodyTinyTitle]: variant === "body-tiny-title",
          [styles.bodyTitle]: variant === "body-title",
          [styles.clearH1]: variant === "clear-h1",
          [styles.clearH2]: variant === "clear-h2",
          [styles.clearH3]: variant === "clear-h3",
          [styles.clearH4]: variant === "clear-h4",
          [styles.fancyH1]: variant === "fancy-h1",
          [styles.fancyH2]: variant === "fancy-h2",
          [styles.fancyH3]: variant === "fancy-h3",
          [styles.fancyH4]: variant === "fancy-h4",
          [styles.note]: variant === "note",
          [styles.primary]: color === "primary",
          [styles.secondary]: color === "secondary",
        },
        className
      ),
    [variant, align, display, className, color]
  );
