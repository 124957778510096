import React, { ComponentProps, FC } from "react";

import cn from "classnames";

import { TypographyProps } from "./types";
import { useTypographyClassNames } from "./useTypographyClassNames";

import styles from "./styles.module.scss";

export const Typography: FC<TypographyProps> = ({ children, ...rest }) => {
  const classNames = useTypographyClassNames(rest);
  return <span className={cn(styles.inline, classNames)}>{children}</span>;
};

export const H1: FC<TypographyProps> = ({ children, ...rest }) => {
  const classNames = useTypographyClassNames(rest);
  return (
    <h1 className={cn(styles.block, styles.clearH1, classNames)}>{children}</h1>
  );
};

export const H2: FC<TypographyProps> = ({ children, ...rest }) => {
  const classNames = useTypographyClassNames(rest);
  return (
    <h2 className={cn(styles.block, styles.clearH2, classNames)}>{children}</h2>
  );
};

export const H3: FC<TypographyProps> = ({ children, ...rest }) => {
  const classNames = useTypographyClassNames(rest);
  return (
    <h3 className={cn(styles.block, styles.clearH3, classNames)}>{children}</h3>
  );
};

export const H4: FC<TypographyProps> = ({ children, ...rest }) => {
  const classNames = useTypographyClassNames(rest);
  return (
    <h4 className={cn(styles.block, styles.clearH4, classNames)}>{children}</h4>
  );
};

export const H5: FC<TypographyProps> = ({ children, ...rest }) => {
  const classNames = useTypographyClassNames(rest);
  return (
    <h5 className={cn(styles.block, styles.bodyTitle, classNames)}>
      {children}
    </h5>
  );
};

export const H6: FC<TypographyProps> = ({ children, ...rest }) => {
  const classNames = useTypographyClassNames(rest);
  return (
    <h6 className={cn(styles.block, styles.bodyTinyTitle, classNames)}>
      {children}
    </h6>
  );
};

export const Paragraph: FC<TypographyProps> = ({ children, ...rest }) => {
  const classNames = useTypographyClassNames(rest);
  return <p className={cn(styles.block, classNames)}>{children}</p>;
};

export const Div: FC<TypographyProps & ComponentProps<"div">> = ({
  children,
  onClick,
  ...rest
}) => {
  const classNames = useTypographyClassNames(rest);
  return (
    <div className={cn(styles.block, classNames)} onClick={onClick}>
      {children}
    </div>
  );
};

export const Anchor: FC<TypographyProps> = ({ children, ...rest }) => {
  const classNames = useTypographyClassNames(rest);
  return <a className={cn(styles.inline, classNames)}>{children}</a>;
};
