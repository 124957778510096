class ModalOpenManagerBase {
  protected items: string[] = [];

  isLast(id: string) {
    return this.items[this.items.length - 1] === id;
  }

  pop() {
    return this.items.pop();
  }

  push(id: string) {
    return this.items.push(id);
  }
}

export const ModalOpenManager = new ModalOpenManagerBase();
