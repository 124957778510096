import React, { useState } from "react";

import cn from "classnames";

import { Typography } from "@/ui/components/Typography";

import { TabsProps } from "./types";

import styles from "./styles.module.scss";

export const Tabs = ({
  className,
  tabClassName,
  tabs,
  tabsNavClassName,
}: TabsProps) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className={cn(styles.root, className)}>
      <div className={cn(styles.tabs, tabsNavClassName)}>
        {tabs.map((tab, key) => (
          <button
            key={key}
            className={cn(styles.tab, tabClassName, {
              [styles.active]: activeTab === key,
            })}
            onClick={() => {
              setActiveTab(key);
            }}
            type="button"
          >
            <Typography className={styles.tabText} variant="body-title">
              {tab.title}
            </Typography>
          </button>
        ))}
      </div>
      {tabs.map((_, key) => (
        <div
          key={key}
          className={cn(styles.content, key !== activeTab && styles.hide)}
        >
          {tabs[key].content}
        </div>
      ))}
    </div>
  );
};
