import { FC, memo, useState } from "react";

import { createPortal } from "react-dom";

import { useIsomorphicLayoutEffect } from "@/hooks";

import { PortalProps } from "./types";

export const Portal: FC<PortalProps> = memo(({ children, container }) => {
  const [containerElement, setContainerElement] = useState<Element | null>(
    null
  );

  useIsomorphicLayoutEffect(() => {
    const element =
      (typeof container === "function" ? container() : container) ??
      document.body;

    setContainerElement(element);
  }, [container]);

  if (!containerElement) {
    return null;
  }

  return createPortal(children, containerElement);
});
