import React, { forwardRef, Ref, useCallback, useMemo } from "react";

import Link from "next/link";

import cn from "classnames";

import { Loader16Icon, Loader24Icon } from "@/components/icons";

import {
  BUTTON_THEME_TO_MODIFIER_MAP,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from "./constants";
import { ButtonProps } from "./types";

import styles from "./styles.module.scss";

const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  (
    {
      as = "button",
      href,
      className,
      children,
      disabled,
      endIcon,
      fullWidth,
      id,
      loading,
      nextLinkProps,
      onClick,
      rel,
      shape,
      size,
      startIcon,
      target,
      testId,
      theme,
      type = ButtonType.Button,
      variant = ButtonVariant.Filled,
    },
    ref
  ) => {
    const handleClick = useCallback(
      (event) => {
        if (disabled || loading) {
          return;
        }

        if (onClick) {
          onClick(event);
        }
      },
      [disabled, loading, onClick]
    );

    const commonProps = useMemo(
      () => ({
        "data-testid": testId,
        className: cn(
          className,
          styles.root,
          styles[`root--${shape}`],
          styles[`root--${size}`],
          styles[`root--${BUTTON_THEME_TO_MODIFIER_MAP[theme]}`],
          styles[`root--${variant}`],
          {
            [styles["root--disabled"]]: disabled,
            [styles["root--fullWidth"]]: fullWidth,
            [styles["root--loading"]]: loading,
          }
        ),
        id,
        onClick: handleClick,
      }),
      [
        className,
        disabled,
        fullWidth,
        handleClick,
        id,
        loading,
        shape,
        size,
        theme,
        testId,
        variant,
      ]
    );

    const contentComponent = (
      <>
        {startIcon && (
          <span className={cn(styles.icon, styles["icon--start"])}>
            {startIcon}
          </span>
        )}
        <span className={styles.text}>{children}</span>
        {endIcon && (
          <span className={cn(styles.icon, styles["icon--end"])}>
            {endIcon}
          </span>
        )}
        {loading && (
          <span className={cn(styles.icon, styles["icon--loader"])}>
            {size === ButtonSize.Sm || size === ButtonSize.Md ? (
              <Loader16Icon />
            ) : (
              <Loader24Icon />
            )}
          </span>
        )}
      </>
    );

    if (as === "a") {
      const anchorCommonProps = {
        "aria-disabled": disabled,
        rel,
        target,
      };

      return nextLinkProps ? (
        <Link ref={ref as Ref<HTMLAnchorElement>} {...nextLinkProps}>
          <a {...commonProps} {...anchorCommonProps}>
            {contentComponent}
          </a>
        </Link>
      ) : (
        <a
          {...commonProps}
          {...anchorCommonProps}
          ref={ref as Ref<HTMLAnchorElement>}
          href={href}
        >
          {contentComponent}
        </a>
      );
    }

    const buttonCommonProps = {
      disabled,
      type,
    };

    return nextLinkProps ? (
      <Link ref={ref as Ref<HTMLAnchorElement>} {...nextLinkProps}>
        <button {...commonProps} {...buttonCommonProps}>
          {contentComponent}
        </button>
      </Link>
    ) : (
      <button
        {...commonProps}
        {...buttonCommonProps}
        ref={ref as Ref<HTMLButtonElement>}
      >
        {contentComponent}
      </button>
    );
  }
);

Button.displayName = "Button";

export default Button;
