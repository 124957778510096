import React, { useCallback } from "react";

import { Portal } from "@/components/Portal";

import { PORTAL_CONTAINER_SELECTOR } from "./constants";

export const ModalPortal = ({ children }) => {
  const getContainer = useCallback(
    () => document.querySelector(PORTAL_CONTAINER_SELECTOR),
    []
  );

  return <Portal container={getContainer}>{children}</Portal>;
};
