import React from "react";

import cn from "classnames";

import { Loader16Icon } from "@/components/icons";

import { TextFieldProps } from "./types";

import styles from "./styles.module.scss";

// TODO: Need to get back to check ui-kit match
export const TextField = ({
  id,
  name,
  hint,
  label,
  value,
  onChange,
  onFocus,
  onBlur,
  disabled,
  error,
  success,
  loading,
  startComponent,
  endComponent,
  bottomComponent,
  readOnly,
  testId,
  type = "text",
  ...restProps
}: TextFieldProps) => {
  const isFilled = Boolean(value);

  const contentComponent = (
    <label className={styles.content} htmlFor={id}>
      <span className={cn(styles.label, isFilled && styles["label--filled"])}>
        {label}
      </span>
      {loading && (
        <Loader16Icon className={cn(styles.icon, styles.iconLoading)} />
      )}
      <input
        {...restProps}
        autoComplete="off"
        className={cn(styles.input, isFilled && styles["input--filled"])}
        data-testid={testId}
        disabled={disabled}
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        readOnly={readOnly}
        type={type}
        value={value}
      />
    </label>
  );

  return (
    <>
      <div
        className={cn(
          styles.root,
          success && styles["root--success"],
          error && styles["root--error"],
          disabled && styles["root--disabled"],
          readOnly && styles["root--readOnly"]
        )}
      >
        {startComponent || endComponent || bottomComponent ? (
          <>
            <div className={styles.wrapper}>
              {startComponent && (
                <div className={styles.start}>{startComponent}</div>
              )}
              {contentComponent}
              {endComponent && <div className={styles.end}>{endComponent}</div>}
            </div>
            {bottomComponent && (
              <div className={styles.bottom}>{bottomComponent}</div>
            )}
          </>
        ) : (
          contentComponent
        )}
      </div>
      {hint && <div className={styles.hint}>{hint}</div>}
    </>
  );
};

export default TextField;
