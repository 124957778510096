import React from "react";

import { LoadingDotsProps } from "./types";

import styles from "./styles.module.scss";

export const LoadingDots = ({ text = "Loading..." }: LoadingDotsProps) => (
  <div className={styles.root} data-testid="loading-dots">
    <div className={styles.dots}>
      <i className={styles.dot} />
      <i className={styles.dot} />
      <i className={styles.dot} />
    </div>
    {text && <div className={styles.text}>{text}</div>}
  </div>
);
