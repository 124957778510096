import React, { FC, useEffect, useRef } from "react";

import cn from "classnames";
import FocusTrap from "focus-trap-react";
import { isFunction, isUndefined } from "lodash-es";
import { CSSTransition } from "react-transition-group";
import { v4 } from "uuid";

import { Close16Icon } from "@/components/icons";
import { KEYBOARD_KEYS } from "@/constants";

import {
  CSS_TRANSITION_CLASS_NAMES,
  DEFAULT_CSS_TRANSITION_TIMEOUT,
} from "./constants";
import { ModalOpenManager } from "./ModalOpenManager";
import { ModalOpenObserver } from "./ModalOpenObserver";
import { ModalPortal } from "./ModalPortal";
import { ModalProps } from "./types";

import styles from "./styles.module.scss";

export const Modal: FC<ModalProps> = ({
  animation = true,
  backdropClassName,
  children,
  className,
  dialogClassName,
  disableCloseButton,
  disableCloseOnEsc,
  isOpen,
  onClose,
  size,
  title,
  titleClassName,
  disableFocusTrap,
}) => {
  const id = useRef(v4());

  const transitionAppear = isUndefined(isOpen);
  const transitionIn = isUndefined(isOpen) ? true : isOpen;

  useEffect(() => {
    if (!disableCloseOnEsc) {
      const handleDocumentKeyDown = (event: KeyboardEvent) => {
        if (
          event.key === KEYBOARD_KEYS.ESCAPE &&
          isFunction(onClose) &&
          ModalOpenManager.isLast(id.current)
        ) {
          onClose();
        }
      };

      document.addEventListener("keydown", handleDocumentKeyDown);

      return () =>
        document.removeEventListener("keydown", handleDocumentKeyDown);
    }

    return undefined;
  }, [disableCloseOnEsc, onClose]);

  const contentModalBody = (
    <div className={cn(styles.root, className)}>
      <div
        className={cn(styles.backdrop, backdropClassName)}
        onClick={onClose}
      />
      <div
        aria-modal="true"
        className={cn(
          styles.dialog,
          size && styles[`dialog--${size}`],
          dialogClassName
        )}
        role="dialog"
      >
        {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
        {Boolean(title || !disableCloseButton) && (
          <header className={styles.header}>
            {typeof title === "string" ? (
              <h3 className={cn(styles.title, titleClassName)}>{title}</h3>
            ) : (
              title
            )}

            {!disableCloseButton && onClose && (
              <button className={styles.close} onClick={onClose} type="button">
                <Close16Icon />
              </button>
            )}
          </header>
        )}
        {children}
      </div>
    </div>
  );

  const rootComponent = (
    <>
      <ModalOpenObserver id={id.current} />
      {disableFocusTrap && <>{contentModalBody}</>}
      {!disableFocusTrap && <FocusTrap>{contentModalBody}</FocusTrap>}
    </>
  );

  return (
    <ModalPortal>
      {animation && (
        <CSSTransition
          appear={transitionAppear}
          classNames={CSS_TRANSITION_CLASS_NAMES}
          in={transitionIn}
          timeout={DEFAULT_CSS_TRANSITION_TIMEOUT}
          unmountOnExit
        >
          {rootComponent}
        </CSSTransition>
      )}
      {!animation && isOpen && rootComponent}
    </ModalPortal>
  );
};
