import React, { FC, useEffect, useState } from "react";

import cn from "classnames";

import { ArrowDown16Icon } from "../../icons";
import { AccordionProps } from "./types";

import styles from "./styles.module.scss";

const Accordion: FC<AccordionProps> = ({
  children,
  isInitiallyOpen,
  heading,
  isChild,
  id,
}) => {
  const [isOpened, toggleIsOpened] = useState(isInitiallyOpen);

  // TODO: add isOpen prop to component. So we have isOpen and isInitiallyOpen
  useEffect(() => {
    toggleIsOpened(isInitiallyOpen);
  }, [isInitiallyOpen, toggleIsOpened]);

  return (
    <div
      className={cn(
        styles.root,
        isOpened && styles.opened,
        isChild && styles.isChild
      )}
      id={id}
    >
      <header
        className={styles.header}
        onClick={() => toggleIsOpened(!isOpened)}
      >
        {isChild ? <h3>{heading}</h3> : <h2>{heading}</h2>}
        <div className={styles.icon}>
          <ArrowDown16Icon />
        </div>
      </header>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default Accordion;
