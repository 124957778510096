export enum ButtonShape {
  Rounded = "rounded",
}

export enum ButtonSize {
  Sm = "sm",
  Md = "md",
  Lg = "lg",
  Xl = "xl",
}

export enum ButtonTheme {
  LegacyBlue = "legacy-blue",
  LegacyRedReverse = "legacy-red-reverse",
  LegacySecondary = "legacy-secondary",
  LegacyTransparent = "legacy-transparent",
  LegacyLightGreen = "legacy-light-green",
  LightGrey = "light-grey",
  Primary = "primary",
}

export enum ButtonType {
  Button = "button",
  Submit = "submit",
}

export enum ButtonVariant {
  Filled = "filled",
  Outlined = "outlined",
  Text = "text",
}

export const BUTTON_THEME_TO_MODIFIER_MAP: Record<`${ButtonTheme}`, string> = {
  "legacy-blue": "legacyBlue",
  "legacy-red-reverse": "legacyRedReverse",
  "legacy-secondary": "legacySecondary",
  "legacy-transparent": "legacyTransparent",
  "legacy-light-green": "legacyLightGreen",
  "light-grey": "lightGrey",
  primary: "primary",
};
