import React from "react";

import cn from "classnames";

import { SkeletonColorScheme } from "./constants";
import { SkeletonProps } from "./types";

import styles from "./styles.module.scss";

// TODO: borderRadius: xs | sm | ...
// withAnimation ?
export const Skeleton = ({
  children,
  className,
  colorScheme = SkeletonColorScheme.Alabaster,
}: SkeletonProps) => (
  <span
    className={cn(
      styles.root,
      {
        [styles["root--whiteChocolate"]]:
          colorScheme === SkeletonColorScheme.WhiteChocolate,
      },
      className
    )}
  >
    {children ? children : <>&zwnj;</>}
  </span>
);
