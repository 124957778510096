import { useEffect } from "react";

import { useBodyScrollBlock } from "@/hooks";

import { ModalOpenManager } from "./ModalOpenManager";
import { ModalOpenObserverProps } from "./types";

export const ModalOpenObserver = ({ id }: ModalOpenObserverProps) => {
  const [blockBodyScroll, allowBodyScroll] = useBodyScrollBlock();

  useEffect(() => {
    ModalOpenManager.push(id);
    blockBodyScroll();

    return () => {
      ModalOpenManager.pop();
      allowBodyScroll();
    };
  }, [allowBodyScroll, blockBodyScroll, id]);

  return null;
};
